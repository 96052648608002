<template>
  <div data-app>
    <v-row class="align-center justify-center">
      <v-col cols="6" class="">
        <v-row class="ma-0 pt-3">
          <v-col cols="3" class="pa-0">
            <span class="text-body-1">Tên bài viết</span>
          </v-col>
          <v-col cols="9" class="pa-0">
            <hnr-input @active="search" v-model="keyword"></hnr-input>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="">
        <v-row class="align-center justify-center ma-0">
          <v-col cols="6" class="pa-0">
            <v-btn color="primary" @click="search">Tìm kiếm</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template>
      <v-data-table
          :headers="headers"
          :items="users"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách User
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
<!--        <template v-slot:no-results>-->
<!--          <v-alert :value="true" color="error" icon="warning">-->
<!--            Your search found no results.-->
<!--          </v-alert>-->
<!--        </template>-->
      </v-data-table>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {GET_LIST_USERS_ASK,DELETE_USER_ASK} from "@/store/user.module";
import {validationMixin} from "vuelidate";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR} from "@/store/alert.module";
import HnrInput from "@/components/elements/hnr-input";
import JwtService from "@/common/jwt.service";

export default {
  mixins: [validationMixin],
  components: {
    HnrInput
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id"
      },
      {text: "Tên đăng nhập", value: "full_name"},
      {text: "Email", value: "email"},
      {text: "SDT", value: "phone"},
      {text: "tổng điểm", value: "vote_number"},
      {text: "tổng điểm tháng hiện tại", value: "vote_number_in_month"},
      {text: "Xóa", value: "actions"},
    ],
    total: 0,
    keyword : ''
  }),

  computed: {
    ...mapState({
      users: state => state.user.listUsers
    }),
  },
  watch: {
    users: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.total = newVal.length;
        }
      }
    }
  },
  created() {
    this.authority = JwtService.getAuthority() || 1;
    this.getUsers();
    this.$store.dispatch(SET_BREADCRUMB, [{title: "User", route: "user"}]);
  },
  methods: {
    getUsers() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(GET_LIST_USERS_ASK).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
      ;
    },
    search(){
      let payload = {
        keyword : this.keyword,
      }
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(GET_LIST_USERS_ASK,payload).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
      ;
    },
    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getUsers();
    },

    updatePage($event) {
      this.page = $event;
      this.getUsers();
    },
    deleteItem(item) {
      const index = this.users.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
      this.$store.dispatch(DELETE_USER_ASK, item.id).then((data) => {
        if (data.status) {
          this.users.splice(index, 1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

    },
  }
};
</script>
